



















































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import config from '@/config'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import {
    apiStorehouseDel,
    apiStorehouseStatus, // 修改仓库状态
    apiStorehouseList // 仓库列表
} from '@/api/goods'
import { apiGetGiftcardListList } from '@/api/marketing/gift_card'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    config = config
    $router: any
    pager = new RequestPaging()
    imgList: any = []
    imgNameList: any = []
    apiGetGiftcardListList = apiGetGiftcardListList
    getList(page?: number): void {
        console.log(this.pager)
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiGetGiftcardListList,
            params: {
                card_id: this.$route.query.id
            }
        })
    }
    handleStatus(value: number, row: object) {
        let params: any = Object.create(null)
        params = row
        params.status = value
        apiStorehouseStatus(params).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
        apiStorehouseDel({ id }).then(() => {
            this.getList()
        })
    }
    // 表格多选下载图片
    getImgList1(val: any) {
        console.warn("val", val)
        let imgList: any = []
        let imgNameList:any = []
        val.forEach((item: any, index: any) => {
            // let obj: any = {}
            // obj.fileUrl = config.baseURL + '/' + item.qrcode
            // obj.renameFileName = index
            // imgList.push(obj)
            // 
            imgList.push(config.baseURL + '/' + item.qrcode + "?t=" + new Date().getTime())
            imgNameList.push(index.toString())
        })
        this.imgList = [...imgList]
        this.imgNameList = [...imgNameList]
        console.log(this.imgList)
    }
     // 表格多选下载图片
    getImgList(val: any) {
        console.warn("val", val)
        let imgList: any = []
        val.forEach((item: any, index: any) => {
            let obj: any = {}
            obj.fileUrl = config.baseURL + '/' + item.qrcode + "?t=" + new Date().getTime()
            obj.renameFileName = index
            imgList.push(obj)
       
        })
        this.imgList = [...imgList]
        console.log(this.imgList)
    }
    // 下载图片
    download() {
        this.filesToRar(this.imgList, '二维码')
    }

    /**文件打包
     * arrImages:文件list:[{fileUrl:文件url,renameFileName:文件名}]
     * filename 压缩包名
     * */
    filesToRar(arrImages: any, filename: any) {
        let _this: any = this
        let zip: any = new JSZip()
        let cache: any = {}
        let promises: any = []
        console.log('正在加载压缩文件')
        for (let item of arrImages) {
            const promise: any = _this
                .getImgArrayBuffer(item.fileUrl)
                .then((data: any) => {
                    // 下载文件, 并存成ArrayBuffer对象(blob)
                    zip.file((item.renameFileName + '.png'), data, { binary: true }) // 逐个添加文件
                    cache[item.renameFileName] = data
                })
                console.log("promise", promise)
            promises.push(promise)
        }
        Promise.all(promises)
            .then(() => {
                zip.generateAsync({ type: 'blob' }).then((content: any) => {
                    console.log('正在压缩')
                    // 生成二进制流
                    FileSaver.saveAs(content, filename) // 利用file-saver保存文件 自定义文件名
                    console.log('压缩完成')
                })
            })
            .catch(res => {
                _this.$message.error('文件压缩失败')
            })
    }
    //获取文件blob
    getImgArrayBuffer(url: any) {
        let _this: any = this
        return new Promise((resolve, reject) => {
            //通过请求获取文件blob格式
            let xmlhttp: any = new XMLHttpRequest()
            xmlhttp.open('GET', url, true)
            xmlhttp.responseType = 'blob'
            xmlhttp.onload = function (e:any) {
                if (this.status == 200) {
                    resolve(this.response)
                } else {
                    reject(this.status)
                }
            }
            xmlhttp.send()
        })
    }

    created() {
        this.getList()
    }
}
